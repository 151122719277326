<template>
    <div class="attributes">
        <div class="page-header-controls align-items-center">
            <!-- Search Attributes -->
            <TmsSearchField
                v-model.trim="search"
                placeholder="Search attribute name"
                aria-label="search"
                class="flex-grow-1"
                @input="onSearchInputChange"
            />
            <!-- Export Taxonomy-->
            <TmsButton class="teritary" type="submit" :disabled="!isResults" @click="exportCSV">
                Export Taxonomy</TmsButton
            >
            <!-- Import Taxonomy-->
            <tms-s3-upload
                button-class="ms-1"
                :disabled="isApiKeyError"
                label="Import Taxonomy"
                :options="{ maxNumberOfFiles: 1 }"
                upload-key="import-taxonomy"
                @files-picked="importFile"
            />

            <!-- New Attribue-->
            <TmsButton class="teritary" type="submit" :disabled="isApiKeyError" @click="newAttributeModal">
                New Attribute</TmsButton
            >
        </div>

        <!-- Used when has loading state -->
        <div v-if="status.attributesLoading && status.sharesLoading" v-tms-spinner="{ width: 3, scale: 2 }" />
        <!-- Attribute List -->
        <div v-else-if="isResults && !status.attributesLoading && !status.sharesLoading">
            <AttributeList
                :attributes="attributes"
                :shares="shares"
                :col-settings="colSettings"
                @edit-attribute="editAttribute"
                @update-attribute="updateAttribute"
                @edit-cancel="editCancel"
                @sort-changed="columnChange"
            />
        </div>

        <!-- Else No Attributes-->
        <div v-else>
            <h6>No Attributes!!</h6>
        </div>
    </div>
</template>
<script>
import csvLine from 'csv-line';
import { defineComponent } from 'vue';

import { TmsFlashMessageUtils, downloadFile } from '@pushspring/tms-client-common';

import AttributeList from '../components/AttributeList.vue';
import NewAttributeModal from '../components/NewAttributeModal.vue';
import { getApiKey } from '../utils/apikeys';
import { getAttributes, importTaxonomy, update } from '../utils/attributes';
import { listShares } from '../utils/shares';

export default defineComponent({
    name: 'AttributesPage',
    components: { AttributeList },
    data() {
        return {
            search: '',
            attributes: [],
            shares: [],
            apiKey: '',
            status: {
                attributesLoading: false,
                sharesLoading: false,
            },
            colSettings: { column: 'name', order: 'asc' },
            originalAttributes: [],
            uploadedFilename: null,
        };
    },
    computed: {
        //check if show results
        isResults() {
            return this.attributes.length > 0;
        },

        //check if apikey has error
        isApiKeyError() {
            return this.apiKey === 'error';
        },
    },
    async created() {
        //set key for accessing api
        try {
            await getApiKey();
        } catch (e) {
            this.apiKey = 'error';
            TmsFlashMessageUtils.add('error', 'There was an error accessing an api key.');
            return;
        }

        //load all attributes and shares
        this.getShares();
        this.getAllAttributes();

        //remove after and before space on search input / set search params
        this.search = this.$route.query?.term ? this.$route.query?.term : this.search.replace(/\s/g, '');

        //check if query params has column and order then set it
        if (this.$route.query?.column) {
            this.colSettings.column = this.$route.query?.column;
            this.colSettings.order = this.$route.query?.order;
        }
    },
    methods: {
        //get all shares
        async getShares() {
            this.status.sharesLoading = true;
            try {
                const orgShares = await listShares();
                this.shares = orgShares.map((share) => {
                    const shareData = { ...share };
                    shareData.isChecked = false;
                    return shareData;
                });
            } catch (e) {
                TmsFlashMessageUtils.add('error', e);
            } finally {
                this.status.sharesLoading = false;
            }
        },

        //get all attributes
        async getAllAttributes() {
            this.status.attributesLoading = true;
            try {
                //getting all attributes
                const attributes = await getAttributes();
                this.attributes = attributes.map((attribute) => {
                    const attributesData = { ...attribute };
                    attributesData.orgs_shared_with = [];
                    attributesData.editAttribute = null;
                    attributesData.edit = false;
                    return attributesData;
                });

                //used for search attributes
                this.originalAttributes = this.attributes;
                //if query params term has value then filter attributes
                if (this.$route.query?.term) {
                    this.attributes = this.filtered(this.colSettings, this.search);
                }
            } catch (e) {
                TmsFlashMessageUtils.add('error', e);
            } finally {
                this.status.attributesLoading = false;
            }
        },

        //mapping org share names
        mapOrgName(attribute) {
            attribute.orgs_shared_with = this.shares
                .filter((share) => attribute.visibility_whitelist.includes(share.org_id))
                .map((obj) => obj.company_name);
        },

        //update attribute
        async updateAttribute(attributetoUpdate) {
            TmsFlashMessageUtils.clear();
            try {
                //if share attribute changed
                if (attributetoUpdate.visibility_whitelist.length > 0) {
                    this.mapOrgName(attributetoUpdate);
                }
                const updatedAttribute = { ...attributetoUpdate, edit: false, editUser: null };
                const oldAttributeIndex = this.attributes.findIndex((attribute) => {
                    return attribute.attribute_id === attributetoUpdate.attribute_id;
                });
                this.attributes[oldAttributeIndex] = updatedAttribute;
                await update(attributetoUpdate);
                this.editCancel(attributetoUpdate);
                TmsFlashMessageUtils.replace('success', attributetoUpdate.name + ' updated successfully.');
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            }
        },
        editAttribute(attribute) {
            attribute.edit = true;
            attribute.editAttribute = { ...attribute };
        },
        editCancel(attribute) {
            attribute.edit = false;
            attribute.editAttribute = null;
        },

        //open new attribute modal
        async newAttributeModal() {
            //set key for accessing api
            try {
                TmsFlashMessageUtils.clear();
                await getApiKey();
            } catch (e) {
                TmsFlashMessageUtils.replace('error', 'There was an error accessing an api key.');
                return;
            }
            const response = await this.tmsShowModal(NewAttributeModal);
            //if has status success then load all attributes with new attribute
            if (response) {
                this.getAllAttributes();
                TmsFlashMessageUtils.add('success', 'New Attribute added successfully.');
            }
        },

        //search input change then set router query params and filter results
        onSearchInputChange(value) {
            if (this.$route.query.term !== value && value) {
                this.attributes = this.filtered(this.colSettings, value);
            } else if (value.length === 0) {
                this.attributes = this.originalAttributes;
            }
            this.$router.replace({ query: { term: value, ...this.colSettings } });
        },

        //filter results based on column settings and search value
        filtered({ column, order }, search) {
            let filtered = this.originalAttributes;
            const compare = (a, b) => (a[column] ?? '')?.toString().localeCompare((b[column] ?? '')?.toString());
            if (search) {
                filtered = this.originalAttributes.filter((attribute) =>
                    attribute.name.toLowerCase().includes(search.toLowerCase()),
                );
            }
            if (order === 'desc') {
                filtered = filtered.sort((a, b) => compare(b, a));
            } else {
                filtered = filtered.sort(compare);
            }
            return filtered;
        },

        //if column order change then filter results
        columnChange(settings) {
            this.colSettings.order = settings.order;
            this.colSettings.column = settings.column;
            //set query params as well
            this.$router.replace({ query: { term: this.search, ...this.colSettings } });
            this.attributes = this.filtered(this.colSettings, this.search);
        },

        //import attributes
        async importFile(uploadedFiles, failedFiles) {
            try {
                TmsFlashMessageUtils.clear();
                if (uploadedFiles) {
                    // Only uploading 1 file by default
                    const file = uploadedFiles[0];
                    this.status.attributesLoading = true;
                    this.uploadedFilename = file.filename;
                    await importTaxonomy(file.key);
                    this.getAllAttributes();
                    TmsFlashMessageUtils.replace('success', 'Attributes successfully imported.');
                } else if (failedFiles) {
                    TmsFlashMessageUtils.replace('error', `Failed to upload`);
                }
            } catch (err) {
                TmsFlashMessageUtils.replace('error', `There was a problem importing the attributes: ${err}`);
                throw new Error(err);
            } finally {
                this.status.attributesLoading = false;
            }
        },

        //export CSV
        async exportCSV() {
            TmsFlashMessageUtils.clear();
            const csv = csvLine();
            let csvData = 'Id,Name,Description,Partner Key,Visible,Device Count,Shared With\r\n';
            this.attributes.forEach((line) => {
                csvData +=
                    csv.encode([
                        line.attribute_id,
                        line.name,
                        line.description,
                        line.partner_foreign_key,
                        line.visible,
                        line.device_count,
                        line.orgs_shared_with.join(','),
                    ]) + '\r\n';
            });
            const blob = new Blob([csvData], { type: 'text/csv' });
            downloadFile(blob, 'partner_attributes.csv');
            TmsFlashMessageUtils.replace('success', 'Taxonomy being downloaded.');
        },
    },
});
</script>
<style lang="scss" scoped>
.attributes {
    .tms-button {
        margin-left: 8px;
    }

    h6 {
        text-align: center;
        font-size: 22px;
        padding: 10px;
    }
}
</style>
